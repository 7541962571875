<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            <template v-if="blogID">
                {{ sprintf($t('pages.module.blog.save.editBlog'), [blog.translate.title ?? '']) }}
            </template>
            <template v-else>{{ $t('pages.module.blog.save.newBlog') }}</template>
        </h2>

        <router-link to="/module/blog" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("pages.module.blog.title") }}
        </router-link>
    </div>
    <el-form :model="form" ref="blogForm">
        <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row mb-1">
                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.blog.save.cols.title') }}</label>
                            <el-form-item prop="translate.title" :rules="$validation.getMessage(['required'])">
                                <el-input v-model="form.translate.title" type="text"/>
                            </el-form-item>
                        </div>
                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.blog.save.cols.description') }}</label>
                            <el-form-item prop="translate.description">
                                <el-input v-model="form.translate.description" type="textarea" rows="3" maxlength="512"/>
                            </el-form-item>
                        </div>
                        <div class="fv-row mb-1">
                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.blog.save.cols.content') }}</label>
                            <el-form-item prop="translate.content" :rules="$validation.getMessage(['required'])">
                                <CustomCkeditor :model="form.translate.content" @updateModel="form.translate.content = $event"></CustomCkeditor>
                            </el-form-item>
                        </div>
                    </div>
                </div>

                <div class="card mt-7">
                    <div class="card-header pt-4 pb-4">
                        <h3 class="card-title align-items-start flex-column">
                            {{ $t('pages.module.blog.save.seoSettings') }}
                        </h3>
                    </div>
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.blog.save.seoCols.slug') }}</label>
                            <el-form-item prop="translate.meta.slug">
                                <el-input v-model="form.translate.slug" type="text"/>
                            </el-form-item>
                        </div>
                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.blog.save.seoCols.title') }}</label>
                            <el-form-item prop="translate.meta.title">
                                <el-input v-model="form.translate.meta.title" type="text"/>
                            </el-form-item>
                        </div>
                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.blog.save.seoCols.description') }}</label>
                            <el-form-item prop="translate.meta.description">
                                <el-input v-model="form.translate.meta.description" type="textarea" rows="3"/>
                            </el-form-item>
                        </div>
                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.blog.save.seoCols.keywords') }}</label>
                            <el-form-item prop="translate.meta.keyword">
                                <el-input v-model="form.translate.meta.keyword" type="textarea" rows="3"/>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row mb-7">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.blog.save.cols.image') }}</label>
                            <el-form-item prop="data.image_id">
                                <el-upload :accept="$root.acceptFileSupported('image', 'image/')" v-bind:class="{'disabled-upload': image.fileList.length > 0}" :on-change="handleChangeImage" :on-remove="handleChangeImage"
                                           list-type="picture-card" :file-list="image.fileList" limit="1" :on-preview="handlePictureCardPreview" :auto-upload="false">
                                    <i class="bi bi-plus"/>
                                </el-upload>
                                <el-dialog v-model="image.dialogVisible" width="30%">
                                    <img style="width: 100%" :src="image.dialogImageUrl" alt=""/>
                                </el-dialog>
                            </el-form-item>
                        </div>

                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.productManagement.product.save.cols.categories') }}</label>
                            <el-form-item prop="data.categories">
                                <el-select v-model="form.data.categories" class="w-100" multiple :placeholder="$t('common.chooseSelect')">
                                    <el-option v-for="(category, categoryIndex) in categories" :key="categoryIndex" :value="category.id" :label="category.translate.title"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('common.sort') }}</label>
                            <el-form-item prop="data.sort" :rules="$validation.getMessage(['required'])">
                                <el-input-number v-model="form.data.sort" :min="1"/>
                            </el-form-item>
                        </div>

                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                            <el-form-item prop="data.active">
                                <el-radio-group v-model="form.data.active">
                                    <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                    <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </div>
                        <div class="fv-row mt-5">
                            <el-form-item class="mb-0">
                                <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                    <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                    <span v-if="form.loading" class="indicator-progress">
                                        {{ $t("messages.wait") }}
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
import CustomCkeditor from "@/components/custom-ckeditor"

export default {
    name: "_id",
    components: {
        CustomCkeditor
    },
    data() {
        return {
            form: {
                updateStatus: false,
                loading: false,
                translate: {
                    language_id: this.$root.defaultLanguage.id,
                    meta: {}
                },
                data: {
                    sort: 1,
                    active: true
                }
            },
            blog: {
                translate: {}
            },
            image: {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            },
        }
    },
    computed: {
        blogID() {
            return this.$route.params.id;
        },
        categories() {
            let categories = this.$store.state.module.blog.category.table.data.map((category) => {
                category.translate = this.resolveDatum(category.translations, this.$root.defaultLanguage.id, 'language_id')

                return category;
            });

            return categories;
        }
    },
    created() {
        if (this.blogID && !(this.blogID > 0)) {
            this.$router.push({
                path: "/module/blog"
            });
        }
    },
    mounted() {
        this.$store.dispatch('module/blog/category/get', {
            page: { pageSize: 9999 }
        });

        if (this.blogID && this.blogID > 0) {
            this.loadBlog();
        }
    },
    methods: {
        loadBlog(blogID = this.blogID) {
            this.resetImageData();

            this.axios.get(this.endpoints['module_blog'] + '/' + blogID).then((response) => {
                let data = response.data.data;

                let translateLinks = this.resolveDatum(data.links, this.$root.defaultLanguage.id, 'language_id');
                data.translate = this.resolveDatum(data.translations, this.$root.defaultLanguage.id, 'language_id');

                data.categories = data.categories.map((category) => {
                    return category.id
                });

                this.blog = data;

                this.form.translate = Object.assign(data.translate, {
                    meta: {
                        title: translateLinks.title,
                        description: translateLinks.description,
                        keyword: translateLinks.keyword
                    }
                });

                this.form.updateStatus = true;
                this.form.data = data;

                if (data.image) {
                    this.image.fileList = [
                        {
                            name: data.image.name,
                            url: data.image.public_url
                        }
                    ]
                }
            })
        },
        onSubmit() {
            this.$refs.blogForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;

                    if (this.image.uploadList.length > 0) {
                        this.submitFormWithUpload();
                    } else {
                        if (!(this.image.fileList.length > 0)) {
                            this.form.data.image_id = null;
                        }
                        this.submitForm();
                    }
                } else {
                    return false;
                }
            });
        },
        submitFormWithUpload() {
            const formData = new FormData();

            this.image.uploadList.forEach(file => {
                formData.append("file[]", file.raw);
            });

            this.axios.post(this.endpoints["file"], formData).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;
                    this.form.data.image_id = data[0].id;
                    this.image.uploadList = [];
                    this.submitForm();
                }, () => {
                    this.form.loading = false;
                }, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                    this.form.loading = false;
                });
            });
        },
        submitForm() {
            let formData = this.prepareFormData();

            this.form.loading = true;

            if (this.form.data.id) {
                this.axios.put(this.endpoints['module_blog'] + '/' + this.form.data.id, formData).then(response => {
                    this.onResponse(response.data, () => {
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            } else {
                this.axios.post(this.endpoints['module_blog'], formData).then(response => {
                    this.onResponse(response.data, () => {
                        this.$router.push({
                            path: "/module/blog/save/" + response.data.data.id
                        });
                        this.loadBlog(response.data.data.id);
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            }
        },
        prepareFormData() {
            let formData = this.form.data;

            return {...this.form.translate, ...formData}
        },
        resetImageData() {
            this.image = {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            }
        },
        handlePictureCardPreview(file) {
            this.image.dialogImageUrl = file.url
            this.image.dialogVisible = true
        },
        handleChangeImage(file, fileList) {
            this.image.fileList = fileList;
            this.image.uploadList = fileList;
        }
    }
}
</script>

<style>
</style>